import {extendTheme} from "native-base";
import {Platform} from "react-native";

const defaultFonts = () => {
  if (Platform.OS === 'web') {
    return {
      fonts: {
        heading: 'Inter',
        body: 'Inter',
        mono: 'Inter',
      },
    };
  }
}

export const theme = extendTheme({
  gradient: {
    colors: ['#108461', '#006849'],
    start: [0, 0],
    end: [0, 1],
  },
  colors: {
    footer: '#102321',
    primary: {
      50: '#cfe6df',
      100: '#9fcec0',
      200: '#70b5a0',
      300: '#409d81',
      400: '#289071',
      500: '#108461', // Must be
      600: '#0d6a4e',
      700: '#0a4f3a',
      800: '#063527',
      900: '#031a13',
    },
    secondary: {
      50: '#b3d2c8',
      100: '#99c3b6',
      200: '#80b4a4',
      300: '#4d9580',
      400: '#33866d',
      500: '#006849', // Must be
      600: '#00533a',
      700: '#004933',
      800: '#003425',
      900: '#001f16',
    },
    gray: {
      50: '#F6F6F6',
      100: '#E9E9E9',
      200: '#E7E7E7',
      300: '#949494',
      500: '#828282',
      600: '#656565',
      700: '#333333',
      900: '#1A1A1A'
    },
  },
  fontSizes: {
    '2xs': 12,
    'xs': 14,
    'sm': Platform.OS === 'web' ? 14 : 16,
    'md': Platform.OS === 'web' ? 16 : 18,
    'lg': 20,
    'xl': 22,
    '2xl': 26,
    '3xl': 32,
    '4xl': 38,
    '5xl': 50,
    '6xl': 62,
    '7xl': 74,
    '8xl': 98,
    '9xl': 130,
  },
  shadows: {
    1: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
      shadowRadius: 20,
    }
  },

  components: {
    Text: {
      color: 'gray.700',
      defaultProps: {
        fontWeight: 300,
        fontSize: Platform.OS === 'web' ? 'sm' : null,
      }
    },
    Heading: {
      color: 'gray.700',
      defaultProps: {
        fontWeight: Platform.OS === 'ios' ? 600 : 500,
      },
      sizes: {
        lg: {
          fontSize: Platform.OS === 'web' ? ['lg', null, 'lg'] : ['2xl', null, '3xl'],
        }
      }
    },
    Button: {
      baseStyle: {
        rounded: 'lg',
      },
      sizes: {
        lg: {
          paddingTop: 4,
          paddingBottom: 4,
          _text: {
            fontSize: 'lg',
            fontWeight: 500,
          }
        },
      },
      variants: {
        outline: {
          _hover: {
            bg: 'transparent',
          },
          _focusVisible: {
            bg: 'transparent',
          },
          _pressed: {
            bg: 'transparent',
          },
        }
      },
    },
    Select: {
      baseStyle: {
        fontWeight: 400,
        fontSize: 'md',
        paddingTop: 4,
        paddingBottom: 4,
        borderColor: 'gray.300',
        placeholderTextColor: 'gray.300'
      },
    },
    Switch: {
      defaultProps: {
        offTrackColor: 'gray.500',
      },
    },
  },

  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },

  fontConfig: {
    Inter: {
      200: {normal: 'Inter-ExtraLight'},
      300: {normal: 'Inter-Light'},
      400: {normal: 'Inter-Regular'},
      500: {normal: 'Inter-Medium'},
      600: {normal: 'Inter-SemiBold'},
      700: {normal: 'Inter-Bold'},
    }
  },

  fontWeights: {
    semibold: Platform.OS === 'ios' ? 600 : 500,
  },

  ...defaultFonts(),
});

// 2. Get the type of the CustomTheme
type CustomThemeType = typeof theme;

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}
