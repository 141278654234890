import {Box, HStack, Image, Link, Text} from "native-base";
import React from "react";
import LanguagePicker from "../../screens/Welcome/components/LanguagePicker";
import {useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {BottomTabParamList} from "../../Types";
import {useTranslation} from "react-i18next";

const logo = require('../../assets/logo_vertical.png');

export default function Header() {
  const { navigate } = useNavigation<NavigationProp<BottomTabParamList>>();
  const { t } = useTranslation('Web');

  const onLogoPress = () => {
    return navigate('WelcomeStack');
  }

  return <HStack flex={1} flexGrow={0} height={100} shadow={1} paddingX={5} paddingY={4}>
    <Box justifyContent='center' paddingRight={4} borderRightWidth={1} borderRightColor='#DADADA'>
      <Link onPress={onLogoPress}>
        <Image source={logo} width={192} height={55} alt={t('ciop')} />
      </Link>
    </Box>
    <Box justifyContent='center' paddingLeft={4}>
      <Text fontWeight='600' fontSize='sm'>{t('appHeader')}</Text>
      <Text fontWeight='600' fontSize='sm'>{t('appHeader2')}</Text>
    </Box>

    <Box marginLeft='auto' justifyContent='center'>
      <LanguagePicker />
    </Box>
  </HStack>
}
