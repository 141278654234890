import {ImageURISource} from "react-native";
import React from "react";
import THP from "./THP";
import {ProtectionClasses} from "../forms/EquipmentForm";

class THP1 extends THP {
  readonly name: string = 'sprzęt z wymuszonym przepływem klasy THP1';
  readonly image: ImageURISource = require('../assets/equipment/TH.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P1;
}

export default new THP1();
