import {ImageURISource} from "react-native";
import React from "react";
import M from "./M";
import {ProtectionClasses} from "../forms/EquipmentForm";

class M1 extends M {
  readonly name: string = 'maska z filtrami klasy P1';
  readonly image: ImageURISource = require('../assets/equipment/M.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P1;

  readonly code: string = 'P1';
  readonly oil: string = '20';
  readonly aerosol: string = '20';
}

export default new M1();
