import {Box, Icon, Pressable, useBreakpointValue, useTheme} from "native-base";
import {LinearGradient} from "expo-linear-gradient";
import {Foundation} from "@expo/vector-icons";
import {States} from "../StatesEnum";
import React, {useState} from "react";
import {Dimensions} from "react-native";
import {useIsPressed} from "native-base/lib/typescript/components/primitives/Pressable/Pressable";

export default function ClockButton(
  {timer, onPress}: {timer: {state: States}, onPress: Function}
) {
  const {width} = Dimensions.get('screen');
  const divider = useBreakpointValue({base: 4, md: 6});
  const buttonSize = Math.round(width / divider);
  const {gradient} = useTheme();
  const [isPressed, setIsPressed] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return <Pressable
    padding={2}
    position='relative'
    height={buttonSize}
    width={buttonSize}
    top={-buttonSize}
    alignSelf='center'
    borderRadius={buttonSize*2}
    shadow={1}
    bg={isPressed ? '#d6d3d1' : (isHover ? '#e7e5e4': 'white')}
    onPress={() => onPress()}
    onPressIn={() => setIsPressed(true)}
    onPressOut={() => setIsPressed(false)}
    onHoverIn={() => setIsHover(true)}
    onHoverOut={() => setIsHover(false)}
  >
    <Box flex={1}
         width='100%'
         justifyContent='center'
         alignItems='center'
         borderRadius={1000}
         overflow='hidden'
    >
      <LinearGradient {...gradient}
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon
          as={Foundation}
          name={timer.state === States.RUNNING ? 'pause' : 'play'}
          paddingLeft={timer.state === States.RUNNING ? 0 : 2}
          size={16}
          color={isPressed ? '#d6d3d1' : (isHover ? '#e7e5e4': 'white')}
          textAlign='center'
        />
      </LinearGradient>
    </Box>
  </Pressable>
}
