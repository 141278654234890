import {ImageURISource} from "react-native";
import React from "react";
import P from "./P";
import {ProtectionClasses} from "../forms/EquipmentForm";

class P2 extends P {
  readonly name: string = 'półmaska z filtrami klasy P2';
  readonly image: ImageURISource = require('../assets/equipment/P.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P2;

  readonly code: string = 'P2';
  readonly oil: string = '6';
  readonly aerosol: string = '6';
}

export default new P2();
