import {createStackNavigator} from "@react-navigation/stack";
import * as React from "react";
import FormScreen from "./FormScreen";
import ResultsScreen from "./ResultsScreen";
import {useTranslation} from "react-i18next";
import EquipmentDetailScreen from "./EquipmentDetailScreen";
import {EquipmentTypes} from "../../equipment";
import {NativeScreenOptions, ScreenOptions} from "../index";
import {Platform} from "react-native";
import {createNativeStackNavigator} from "react-native-screens/native-stack";

export type FormParamList = {
  Form: undefined;
  Results: undefined;
  EquipmentDetail: {
    id: number;
  },
};

// @ts-ignore
let FormStack;
let options;

// if (Platform.OS === 'web') {
  FormStack = createStackNavigator<FormParamList>();
  options = ScreenOptions;
// } else {
//   FormStack = createNativeStackNavigator<FormParamList>();
//   options = NativeScreenOptions;
// }

export default function FormNavigator() {
  const { t } = useTranslation();

  // @ts-ignore
  // @ts-ignore
  return (
    // @ts-ignore
    <FormStack.Navigator
      screenOptions={ScreenOptions}
    >
      {/*@ts-ignore*/}
      <FormStack.Screen
        name="Form"
        component={FormScreen}
        options={{
          title: t('FormScreen:title'),
          headerTitle: t('FormScreen:title'),
        }}
      />

      {/*// @ts-ignore*/}
      <FormStack.Screen
        name="Results"
        component={ResultsScreen}
        options={{
          title: t('ResultsScreen:title'),
          headerTitle: t('ResultsScreen:title'),
        }}
      />

      {/*@ts-ignore*/}
      <FormStack.Screen
        name="EquipmentDetail"
        component={EquipmentDetailScreen}
        // @ts-ignore
        options={({route}) => {
          const equipment = EquipmentTypes[route.params.id];

          let title = equipment.name ?? t('EquipmentDetailScreen:title');
          title = title.charAt(0).toUpperCase() + title.slice(1);

          return {
            title,
            headerTitle: title,
          }
        }}
      />
    {/* @ts-ignore*/}
    </FormStack.Navigator>
  );
}
