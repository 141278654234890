import React from 'react';
import {Box, Center, Heading, HStack, Image, ScrollView, Text, VStack, Hidden, View, Link} from 'native-base';
import GradientButton from "../../components/GradientButton";
import {useTranslation} from 'react-i18next';
import LanguagePicker from "./components/LanguagePicker";
import {useLinkTo, useNavigation} from "@react-navigation/native";
import {BottomTabParamList} from "../../Types";
import {NavigationProp} from "@react-navigation/core/src/types";
import ContentWrapper from "../../components/ContentWrapper";

const logo = require('../../assets/logo.jpg');

export default function WelcomeScreen() {
  const { t, i18n } = useTranslation('WelcomeScreen');
  const { navigate } = useNavigation<NavigationProp<BottomTabParamList>>();

  const onPressHandler = () => {
    navigate('FormStack');
  }

  return (
    <ContentWrapper>
      <Box safeArea>
        <VStack space={4}>
          <Hidden platform={['web']}>
            <View marginTop={4}>
              <LanguagePicker />

              <Center marginTop={6}>
                <Image source={logo} width={200} height={86} alt='Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy' />
              </Center>
            </View>
          </Hidden>

          <Heading size='lg'>{t('header')}</Heading>
          <Text textAlign='justify'>{t('description')}</Text>

          <Heading size='lg'>{t('howItWorks')}</Heading>
          <VStack space={2}>
            <HStack space={1} alignItems='center'>
              <Box {...params.point}>
                <Text color='primary.500'>{1}</Text>
              </Box>
              <Text fontWeight='semibold' fontSize='sm'>{t('howItWorksPoints.0')}</Text>
            </HStack>

            <HStack space={1} alignItems='center'>
              <Box {...params.point}>
                <Text color='primary.500'>{2}</Text>
              </Box>
              <Text fontWeight='semibold' fontSize='sm'>{t('howItWorksPoints.1')}</Text>
            </HStack>

            <Hidden platform={['web']}>
              <HStack space={1} alignItems='center'>
                <Box {...params.point}>
                  <Text color='primary.500'>{3}</Text>
                </Box>
                <Text fontWeight='semibold' fontSize='sm'>{t('howItWorksPoints.2')}</Text>
              </HStack>
            </Hidden>
          </VStack>

          <GradientButton
            _gradientStack={{flex: 1}}
            size='lg'
            onPress={onPressHandler}
          >
            {t('start')}
          </GradientButton>

          <Hidden platform={['android', 'ios']}>
            <Box bg='#E8FDF7' borderWidth={1} borderColor='#A5F4DD' borderRadius='md' padding={5}>
              <Box>
                <Text>{t('download')}</Text>
              </Box>

              {i18n.language === 'en'
                ? <HStack marginTop={4} space={4}>
                    <Image width={152} height={45} source={require('../../assets/download_appstore_en.svg')} alt={t('download_appstore')} />
                    <Image width={180} height={47} source={require('../../assets/download_gplay_en.png')} alt={t('download_gplay')} />
                  </HStack>
                : <HStack marginTop={4} space={4}>
                    <Image width={152} height={45} source={require('../../assets/download_appstore.svg')} alt={t('download_appstore')} />
                    <Image width={155} height={45} source={require('../../assets/download_gplay.png')} alt={t('download_gplay')} />
                  </HStack>
              }
            </Box>
          </Hidden>

          <Hidden platform={['web']}>
            <Text fontSize='2xs' color='gray.500' textAlign='justify'>{t('info')}</Text>
          </Hidden>
        </VStack>
      </Box>
    </ContentWrapper>
  );
}

const params = {
  point: {
    borderColor: 'primary.500',
    borderWidth: 1,
    borderRadius: 20,
    w: 6,
    h: 6,
    lineHeight: 6,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
  }
};
