import {ImageURISource} from "react-native";
import React from "react";
import FFP from "./FFP";
import {ProtectionClasses} from "../forms/EquipmentForm";

class FFP1 extends FFP {
  readonly name: string = 'półmaska filtrująca klasy FFP1';
  readonly image: ImageURISource = require('../assets/equipment/FFP1.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P1;

  readonly code: string = 'FFP1';
  readonly oil: string = '20';
  readonly aerosol: string = '20';
  readonly leakage: string = '25/22';
}

export default new FFP1();
