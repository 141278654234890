import {Actionsheet, Button, Heading, Text, VStack} from "native-base";
import GradientButton from "../../../components/GradientButton";
import React from "react";

export default function ActionsheetStop(
  {isOpen = false, onStop, onClose}: {isOpen: boolean, onStop: Function, onClose: Function}
) {
  return <Actionsheet
    isOpen={isOpen}
    onClose={() => onClose()}
  >
    <Actionsheet.Content>
      <VStack w='100%' px={8} py={4} space={2}>
        <Heading textAlign='center'>Koniec pracy</Heading>
        <Text fontSize='md' textAlign='center'>
          Chcesz zakończyć działanie timera i zapisać czas użycia SUOP w historii?
        </Text>

        <GradientButton size='lg' _gradientStack={{flex: 1, marginBottom: -2}} onPress={() => onStop()}>
          Tak, zakończ pracę
        </GradientButton>

        <Button size='lg' variant='unstyled' _text={{color: 'secondary.500'}} onPress={() => onClose()}>
          Nie, wróć do timera
        </Button>
      </VStack>
    </Actionsheet.Content>
  </Actionsheet>
}
