import {ImageBackground, Platform, useWindowDimensions} from "react-native";
import React from "react";
import {Box, HStack, ScrollView, Text, View, VStack} from "native-base";
import Header from "./web/Header";
import Footer from "./web/Footer";

export default function ContentWrapper(params : {children: React.ReactNode}) {
  const { height } = useWindowDimensions();

  if (Platform.OS === 'web') {
    return <ScrollView maxHeight={height} backgroundColor='#FFF' flexGrow={1}>
      <View flexGrow={1} minHeight={height}>
        <Header />

        <Box flexGrow={1}>
          <HStack paddingLeft={5} flexGrow={1}>
            <Box flex={1} paddingRight={5} paddingY={4}>
              {params.children}
            </Box>
            <ImageBackground style={{flex: 1}} source={require('../assets/bg.jpg')} resizeMode='cover'>
              <View
                flex={1}
                position='absolute'
                top={0} right={0} bottom={0} left={0}
                bg='secondary.500'
                opacity={0.9}
              />

              <Box flex={1} alignItems='center' justifyContent='center' paddingX={20}>
                <Text color='white' fontSize='8xl' lineHeight='24' textAlign='center' fontFamily='serif'>“</Text>
                <Text color='white' fontSize='2xl' textAlign='center'>Lorem ipsum sit omnia, dolor et 30% valostro momentum ignitatis</Text>
              </Box>
            </ImageBackground>
          </HStack>
        </Box>

        <Box marginTop='auto'>
          <Footer />
        </Box>
      </View>
    </ScrollView>;
  }

  return <ScrollView flex={1} backgroundColor='#FFF' paddingX={5} maxHeight={height}>
    {params.children}
  </ScrollView>
}
