import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, HStack, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class FFP implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  abstract readonly code: string;
  abstract readonly oil: string;
  abstract readonly aerosol: string;
  abstract readonly leakage: string;

  readonly videos = [
    require('../assets/equipment/FFP.mov'),
    require('../assets/equipment/SealCheck.mov'),
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>Półmaska filtrująca składa się z połączonych na obrzeżu układów włóknin filtracyjnych. Osłania nos, usta i brodę użytkownika. Często, w celu poprawy warunków oddychania, montuje się w nich zawór wydechowy. Półmaski filtrujące są wyposażone w nagłowie, które stanowi układ taśm pasmanteryjnych, gumowych, lateksowych, zapinek lub zauszników. W półmaskach bez zaworu wydechowego przepływ powietrza w fazie wdechu i wydechu następuje przez materiał filtrujący, a w półmaskach z zaworem wydechowym przez materiał filtrujący i zawór wydechowy.</Text>
    <Text>Półmaski filtrujące powinny spełniać wymagania normy PN-EN 149 „Sprzęt ochronny układu oddechowego. Półmaski filtrujące do ochrony przed cząstkami. Wymagania, badania, znakowanie”.</Text>
    <Text>Półmaski filtrujące są klasyfikowane (i znakowane) w odniesieniu od ich skuteczności filtracji i maksymalnego dopuszczalnego całkowitego przecieku wewnętrznego.</Text>

    <VStack space={1}>
      <Text textAlign='center' fontWeight='semibold'>{this.code}</Text>
      <HStack alignItems='center'>
        <Text flex={1}>Maksymalna penetracja mgły oleju parafinowego:</Text>
        <Text fontWeight='semibold'>{this.oil}%</Text>
      </HStack>
      <HStack alignItems='center'>
        <Text flex={1}>Maksymalna penetracja aerozolu chlorku sodu:</Text>
        <Text fontWeight='semibold'>{this.aerosol}%</Text>
      </HStack>
      <HStack alignItems='center'>
        <Text flex={1}>Maksymalny całkowity przeciek wewnętrzny:</Text>
        <Text fontWeight='semibold'>{this.leakage}% *</Text>
      </HStack>
    </VStack>
    <Text fontSize='xs'>* Co najmniej 46 z 50 wyników indywidualnych CPW / co najmniej 8 z 10 średnich CPW dla każdego uczestnika nie powinno być większych niż podana wartość</Text>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>•	Półmaski filtrujące nie powinny być stosowane przez osoby posiadające zarost!</Text>
    <Text>•	Półmaski filtrujące nie powinny być stosowane przez osoby posiadające nieregularne rysy twarzy uniemożliwiające zachowanie szczelności dopasowania!</Text>
    <Text>•	Należy zadbać o kompatybilność półmaski filtrującej z innymi środkami ochrony indywidualnej i okularami korekcyjnymi!</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>Znakowanie półmasek filtrujących powinno obejmować, co najmniej:</Text>
    <Text>•	nazwę producenta lub znak towarowy identyfikujący producenta,</Text>
    <Text>•	adres producenta,</Text>
    <Text>•	znakowanie identyfikujące typ półmaski,</Text>
    <Text>•	symbol właściwej klasy ochrony,</Text>
    <Text>•	numer serii lub datę produkcji,</Text>
    <Text>•	numer i rok wydania właściwej normy polskiej lub europejskiej,</Text>
    <Text>•	znak CE i czterocyfrowy numer jednostki notyfikowanej,</Text>
    <Text>•	symbol NR, jeśli półmaska przeznaczona jest do użycia w ciągu jednej zmiany roboczej lub symbol R, jeśli półmaska przeznaczona jest do użycia wielokrotnego,</Text>
    <Text>•	półmaski filtrujące, które pozytywnie przeszły badanie zatkania pyłem dolomit owym powinny być oznakowane literą D.</Text>
  </Box>;
}
