import {ImageURISource} from "react-native";
import React from "react";
import THP from "./THP";
import {ProtectionClasses} from "../forms/EquipmentForm";

class THP3 extends THP {
  readonly name: string = 'sprzęt z wymuszonym przepływem klasy THP3';
  readonly image: ImageURISource = require('../assets/equipment/TH.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P3;
}

export default new THP3();
