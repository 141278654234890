import {AntDesign} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import * as React from 'react';
import {BottomTabParamList} from "../Types";
import HistoryNavigator from "../screens/History";
import WelcomeNavigator from "../screens/Welcome";
import FormNavigator from "../screens/Form";
import {Platform} from "react-native";
import {Box, Text} from "native-base";
import {LabelPosition} from "@react-navigation/bottom-tabs/lib/typescript/src/types";

function TabBarIcon(props: { name: React.ComponentProps<typeof AntDesign>['name']; color: string }) {
  return <AntDesign size={27} {...props} />;
}

function TabBarLabel(props: {
  color: string;
  focused: boolean;
  position: LabelPosition,
  children: React.ReactNode;
}) {
  if (props.position === 'beside-icon') {
    return (
      <Box flexDirection='row' alignItems='center' marginLeft={5}>
        <Text color={props.color} fontWeight='semibold'>{props.children}</Text>
      </Box>
    );
  }

  return <Box marginBottom={2} borderBottomWidth={props.focused ? 3 : 0} borderBottomColor={props.color}>
    <Text color={props.color} fontWeight='semibold'>{props.children}</Text>
  </Box>;
}

const BottomTab = createBottomTabNavigator<BottomTabParamList>();
export default function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      initialRouteName="WelcomeStack"
      screenOptions={{
        tabBarActiveTintColor: '#108461',
        tabBarStyle: {
          height: Platform.OS === 'ios' ? 95 : 75,
        },
        tabBarLabelPosition: 'below-icon',
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="WelcomeStack"
        component={WelcomeNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>Główna</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="home" color={color}/>,
        }}
      />
      <BottomTab.Screen
        name="FormStack"
        component={FormNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>SOUO</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="form" color={color}/>,
        }}
      />

      <BottomTab.Screen
        name="HistoryStack"
        component={HistoryNavigator}
        options={{
          tabBarLabel: ({color, focused, position}) => <TabBarLabel color={color} focused={focused} position={position}>Historia</TabBarLabel>,
          tabBarIcon: ({color}) => <TabBarIcon name="calendar" color={color}/>,
        }}
      />
    </BottomTab.Navigator>
  );
}
