import {NavigationContainer, useNavigation} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import BottomTabNavigator from './BottomTabNavigator';
import {RootStackParamList} from "../Types";
import TimerScreen from "../screens/Timer/TimerScreen";
import {Platform} from "react-native";
import LinkingConfiguration from "./LinkingConfiguration";
import WebStackNavigator from "./WebStackNavigator";
import {useEffect} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../consts";
import {States} from "../screens/Timer/StatesEnum";
import {NavigationProp} from "@react-navigation/core/src/types";

export default function Navigation() {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Navigator = (Platform.OS === 'web') ? WebStackNavigator : BottomTabNavigator;

const Stack = createStackNavigator<RootStackParamList>();
function RootNavigator() {
  const {navigate} = useNavigation<NavigationProp<RootStackParamList>>();

  useEffect(() => {
    navigateIfTimerExists();
  });

  const navigateIfTimerExists = async () => {
    if (Platform.OS !== 'web') {
      let timer = await AsyncStorage.getItem(STORAGE.TIMER);
      if (timer) {
        let json = JSON.parse(timer);

        if (json?.state !== States.EXPIRED) {
          navigate('Timer');
        }
      }
    }
  };

  return (
    <Stack.Navigator screenOptions={{
      headerShown: false,
      presentation: 'modal',
    }}>
      <Stack.Screen name="Root" component={Navigator} />
      <Stack.Screen name="Timer" component={TimerScreen} />
    </Stack.Navigator>
  );
}
