import {ImageURISource} from "react-native";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export interface IEquipmentType {
  readonly name: string;
  readonly image: ImageURISource;
  readonly videos: { uri: string; }[];
  readonly protectionClass: ProtectionClasses;

  description(t: TFunction): React.ReactNode;
  warnings(t: TFunction): React.ReactNode;
  labeling(t: TFunction): React.ReactNode;
}

export const EquipmentTypes: {[key: string]: IEquipmentType} = {
  FPP1: require("./FPP1").default,
  FPP2: require("./FPP2").default,
  FPP3: require("./FPP3").default,
  P1: require("./P1").default,
  P2: require("./P2").default,
  P3: require("./P3").default,
  M1: require("./M1").default,
  M2: require("./M2").default,
  M3: require("./M3").default,
  THP1: require("./THP1").default,
  THP2: require("./THP2").default,
  THP3: require("./THP3").default,
};

