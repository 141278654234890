import {Box, Image, Text, HStack, Link, VStack} from "native-base";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {BottomTabParamList, RootStackParamList} from "../../Types";
import {NavigationProp} from "@react-navigation/core/src/types";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  return <HStack space={4} paddingX={4} paddingY={10} bg='footer' marginTop='auto'>
    <VStack space={5} flex={1} paddingRight={4}>
      <HStack justifyContent='space-between'>
        <Box>
          <Link href='/'><Image source={require('../../assets/logo_dark.png')} width={198} height={30} /></Link>
        </Box>

        {i18n.language === 'en'
          ? <HStack space={4}>
            <Image width={102} height={34} source={require('../../assets/download_appstore_en.svg')} alt={t('download_appstore')} />
            <Image width={102} height={30} source={require('../../assets/download_gplay_en.png')} alt={t('download_gplay')} />
          </HStack>
          : <HStack space={4}>
            <Image width={102} height={34} source={require('../../assets/download_appstore.svg')} alt={t('download_appstore')} />
            <Image width={102} height={30} source={require('../../assets/download_gplay.png')} alt={t('download_gplay')} />
          </HStack>
        }
      </HStack>
      <HStack space={3}>
        <Link
          _text={{
            color: 'coolGray.100',
            textDecoration: 'none',
          }}
          _hover={{
            _text: {
              color: 'primary.300',
            }
          }}
          onPress={() => navigate('WelcomeStack')}
        >{t('Web:homepage')}</Link>
        <Text color='coolGray.100'>•</Text>
        <Link
          _text={{
            color: 'coolGray.100',
            textDecoration: 'none'
          }}
          _hover={{
            _text: {
              color: 'primary.300',
            }
          }}
          onPress={() => navigate('Root', {screen: 'FormStack'})}
        >{t('Web:test')}</Link>
      </HStack>
    </VStack>

    <Box flex={1} paddingLeft={4}>
      <Text color='coolGray.100' fontSize='2xs'>{t('WelcomeScreen:info')}</Text>
    </Box>
  </HStack>
}
