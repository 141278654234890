import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const { LanguageDetectorPlugin } = require('./LanguageDetectorPlugin');

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  pl: {
    WelcomeScreen: {
      title: 'Strona główna',
      header: "Dzień dobry",
      description: "Ta aplikacja pomoże Ci dobrać odpowiedni środek ochrony układu oddechowego. Dzięki opcji timera, już nigdy nie zapomnisz o jego wymianie zgodnie z zaleceniami producenta.",
      howItWorks: "Jak to działa?",
      howItWorksPoints: [
        "Wypełnij formularz",
        "Sprawdź rekomendacje i wybierz SOUO",
        "Użyj timera, aby kontrolować czas użycia",
      ],
      start: "Zaczynamy!",
      info: "Aplikacja opracowana na podstawie wyników V etapu programu wieloletniego „Poprawa bezpieczeństwa i warunków pracy”, finansowanego w latach 2021-2022 w zakresie zadań służb państwowych ze środków ministra właściwego ds. pracy (zadanie nr 3.SP.07 pt. Opracowanie aplikacji użytkowej do doboru sprzętu ochrony układu oddechowego przed bioaerozolem w wybranych środowiskach pracy). Koordynator Programu: Centralny Instytut Ochrony Pracy – Państwowy Instytut Badawczy",
      download: 'Możesz też pobrać aplikację na telefon. Wersja na urządzenia mobilne posiada dodatkowo timer, który poinformuje Cie o konieczności wymiany SOUO.',
      download_appstore: 'Pobierz z App Store',
      download_gplay: 'Pobierz z Google Play',
      ciop: 'Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy',
    },
    FormScreen: {
      title: 'Wypełnij formularz',
      select: 'Wybierz',
      less: 'Chcę podać mniej danych',
      more: 'Chcę podać więcej danych',
      show_recommended: 'Pokaż rekomendowane SOUO',
      labels: {
        workplace: 'Środowisko pracy',
        environment: 'Miejsce wykonywania pracy',
        oxygen: 'Zawartość tlenu',
        gas_steam_presence: 'Obecność par i gazów w otoczeniu',
        temp: 'Temperatura',
        humidity: 'Wilgotność',
        dust: 'Stężenie pyłu',
        beard: 'Posiadam zarost',
        irregular_face: 'Posiadam nieregularne rysy twarzy',
        glasses: 'Noszę okulary korekcyjne',
        micro_risk_group: 'Rodzaj mikroorganizmów',
        micro_risk_num: 'Ilość mikroorganizmów',
        metabolites_air: 'Rodzaj metabolitów w powietrzu',
        metabolites_dust: 'Rodzaj metabolitów w pyle',
        metabolites_air_num: 'Ilość metabolitów w powietrzu',
        metabolites_dust_num: 'Ilość metabolitów w pyle',
        cytotoxicity_dust: 'Cytotoksyczność pyłu',
        biodiversity_air: 'Bioróżnorodność powietrza',
        biodiversity_dust: 'Bioróżnorodność pyłu',
      },
      options: {
        agriculture: 'Rolnictwo',
        sewage: 'Oczyszczalnia ścieków',
        waste: 'Sortownia odpadów',
        indoor: 'W pomieszczeniu',
        outdoor: 'Na zewnątrz',
        yes: 'Tak',
        no: 'Nie',
        nds1: '<1xNDS',
        nds2: '<1-4)xNDS',
        nds3: '<4-9)xNDS',
        nds4: '<9-20)xNDS',
        nds5: '>=20xNDS',
        microriskGroup1: 'Mikroorganizmy z 1 grupy ryzyka',
        microriskGroup2: 'Mikroorganizmy z 2 grupy ryzyka',
        microriskGroup3: 'Mikroorganizmy z 3 grupy ryzyka',
        microriskGroup4: 'Mikroorganizmy z 4 grupy ryzyka',
        microNum1: '< wartość graniczna',
        microNum2: '<1-4)x wartość graniczna',
        microNum3: '<4-9)x wartość graniczna',
        microNum4: '<9-20)x wartość graniczna',
        microNum5: '>=20x wartość graniczna',
        none: 'Brak',
        endotoxin: 'Endotoksyna obecna',
        mold: 'Metabolity wtórne pleśni obecne',
        other: 'Inne metabolity pochodzenia mikrobiologicznego obecne',
        metabolitesAirNumLow: 'Endotoksyna bakteryjna <200 ng/m3',
        metabolitesAirNumHigh: 'Endotoksyna bakteryjna >200 ng/m3',
        metabolitesDustNumLow: 'Endotoksyna bakteryjna < 0,155 nmol LPS mg-1',
        metabolitesDustNumHigh: 'Endotoksyna bakteryjna > 0,155 nmol LPS mg-1',
        cytotoxicityLow: 'Niska (brak IC50 po 48h)',
        cytotoxicityHigh: 'Wysoka (wyznaczone IC50 po 48h)',
        biodiversityLow: 'Niska (>50% 1 rodzaj mikroorganizmu)',
        biodiversityHigh: 'Wysoka (mikroorganizmy <50%)',
      },
    },
    ResultsScreen: {
      title: 'Rekomendowane SOUO',
      check: 'Sprawdź',

      no_protection_class: 'Brak potrzeby stosowania sprzętu',
      insulating: 'Zastosuj sprzęt izolujący',
      absorbing: 'Zastosuj sprzęt pochłaniający',
    },
    EquipmentDetailScreen: {
      title: 'SOUO',
      description: 'Opis podstawowy',
      howToUse: 'Jak używać?',
      warningsAndLabeling: 'Ostrzeżenia i znakowanie',
      warnings: 'Ostrzeżenia',
      labeling: 'Znakowanie',
      runTimer: 'Uruchom timer',
    },
    HistoryScreen: {
      title: 'Historia',
    },
    Web: {
      ciop: 'Centralny Instytut Ochrony Pracy - Państwowy Instytut Badawczy',
      appHeader: 'Aplikacja doboru',
      appHeader2: 'środków ochrony układu oddechowego',
      homepage: 'Strona Główna',
      test: 'Rozpocznij test',
    },
  },
  en: {
    WelcomeScreen: {
      title: 'Homepaage',
      header: "Hello!",
      description: "This app helps you choose the right respiratory protective equipment. With the timer option, you will never again forget to replace it according to the manufacturer's recommendations.",
      howItWorks: "How it works?",
      howItWorksPoints: [
        "Fill out the form",
        "Check the recommendations and choose the RPE",
        "Use the timer to control the use time",
      ],
      start: "Let's start!",
      info: "The application is based on the results of the V phase of the long-term program “Improvement of safety and working conditions”, financed in the years 2021-2022 in the scope of tasks of state services from the funds of the minister responsible for labour (task no. 3.SP.07 entitled Development of a user application for the selection of respiratory protection equipment against bioaerosol in selected working environments). Coordinator of the Program: Central Institute for Labour Protection – National Research Intitute",
      download: 'You can also download an application to your phone. The mobile version has an additional timer that will inform you when it is time to replace the RPE.',
      download_appstore: 'Download on the App Store',
      download_gplay: 'Download on the Google Play',
      ciop: 'Central Institute for Labour Protection – National Research Intitute',
    },
    FormScreen: {
      title: 'Fill out form',
      select: 'Select',
      less: 'I want to provide less data',
      more: 'I want to provide more data',
      show_recommended: 'Show recommended RPE',

      labels: {
        workplace: 'Work environment',
        environment: 'Place of work',
        oxygen: 'Oxygen content',
        gas_steam_presence: 'Presence of vapours and gases in the environment',
        temp: 'Temperature',
        humidity: 'Humidity',
        dust: 'Dust concentration',
        beard: 'I have facial hair',
        irregular_face: 'I have irregular facial features',
        glasses: 'I wear corrective glasses',
        micro_risk_group: 'Type of microorganisms',
        micro_risk_num: 'Amount of microorganisms',
        metabolites_air: 'Type of metabolites in the air',
        metabolites_dust: 'Type of metabolites in dust',
        metabolites_air_num: 'Number of metabolites in air',
        metabolites_dust_num: 'Number of metabolites in dust',
        cytotoxicity_dust: 'Dust cytotoxicity',
        biodiversity_air: 'Air biodiversity',
        biodiversity_dust: 'Dust biodiversity',
      },
      options: {
        agriculture: 'Agriculture',
        sewage: 'Sewage Treatment Plant',
        waste: 'Waste sorting plant',
        indoor: 'Indoor',
        outdoor: 'Outdoor',
        yes: 'Yes',
        no: 'No',
        nds1: '<1xTLV',
        nds2: '<1-4)xTLV',
        nds3: '<4-9)xTLV',
        nds4: '<9-20)xTLV',
        nds5: '>=20xTLV',
        microriskGroup1: 'Microorganisms in risk group 1',
        microriskGroup2: 'Microorganisms in risk group 2',
        microriskGroup3: 'Microorganisms in risk group 3',
        microriskGroup4: 'Microorganisms in risk group 4',
        microNum1: '< threshold',
        microNum2: '<1-4)x threshold',
        microNum3: '<4-9)x threshold',
        microNum4: '<9-20)x threshold',
        microNum5: '>=20x threshold',
        none: 'None',
        endotoxin: 'Endotoxin present',
        mold: 'Secondary metabolites of mould present',
        other: 'Other metabolites of microbial origin present',
        metabolitesAirNumLow: 'Bacterial endotoxin <200 ng/m3',
        metabolitesAirNumHigh: 'Bacterial endotoxin >200 ng/m3',
        metabolitesDustNumLow: 'Bacterial endotoxin < 0,155 nmol LPS mg-1',
        metabolitesDustNumHigh: 'Bacterial endotoxin > 0,155 nmol LPS mg-1',
        cytotoxicityLow: 'Low (IC50 after 48h)',
        cytotoxicityHigh: 'High (determined IC50 after 48h)',
        biodiversityLow: 'Low (>50% 1 type of microorganism)',
        biodiversityHigh: 'High (microorganisms <50%)',
      },
    },
    ResultsScreen: {
      title: 'Recommended RPE',
      check: 'Check',
    },
    EquipmentDetailScreen: {
      title: 'RPE',
      description: 'Basic description',
      howToUse: 'How to use?',
      warningsAndLabeling: 'Warnings and labeling',
      warnings: 'Warnings',
      labeling: 'Labeling',
      runTimer: 'Run timer',
    },
    Web: {
      ciop: 'Central Institute for Labour Protection – National Research Intitute',
      appHeader: 'Application to choose',
      appHeader2: 'the respiratory protective equipment',
      homepage: 'Homepage',
      test: 'Start test',
    },
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetectorPlugin)
  .init({
    resources,
    compatibilityJSON: 'v3',
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
