import {ImageURISource} from "react-native";
import React from "react";
import M from "./M";
import {ProtectionClasses} from "../forms/EquipmentForm";

class M3 extends M {
  readonly name: string = 'maska z filtrami klasy P3';
  readonly image: ImageURISource = require('../assets/equipment/M.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P3;

  readonly code: string = 'P3';
  readonly oil: string = '0,05';
  readonly aerosol: string = '0,05';
}

export default new M3();
