import {ImageURISource} from "react-native";
import React from "react";
import P from "./P";
import {ProtectionClasses} from "../forms/EquipmentForm";

class P1 extends P {
  readonly name: string = 'półmaska z filtrami klasy P1';
  readonly image: ImageURISource = require('../assets/equipment/P.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P1;

  readonly code: string = 'P1';
  readonly oil: string = '20';
  readonly aerosol: string = '20';
}

export default new P1();
