import {ImageURISource} from "react-native";
import React from "react";
import FFP from "./FFP";

class FFP3 extends FFP {
  readonly name: string = 'półmaska filtrująca klasy FFP3';
  readonly image: ImageURISource = require('../assets/equipment/FFP3.jpg');

  readonly code: string = 'FFP3';
  readonly oil: string = '1';
  readonly aerosol: string = '1';
  readonly leakage: string = '5/2';
}

export default new FFP3();
