export const STORAGE = {
  LANGUAGE: 'settings.lang',
  FORM: 'form',
  PROTECTION_CLASS: 'protection',
  MCU: 'mcu',

  SELECTED_EQUIPMENT: 'selectedEquipment',
  TIMER: 'timer',
  HISTORY: 'history',
};
