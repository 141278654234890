import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, HStack, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class THP implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  readonly videos = [
    require('../assets/equipment/TH.mov'),
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>Filtrujący sprzęt z wymuszonym przepływem powietrza składa się z części twarzowej (np. hełm, kaptur), do której dostarczane jest powietrze poprzez element oczyszczający połączony za pomocą węża dmuchawą noszoną przez użytkownika.</Text>
    <Text>Sprzęt z wymuszonym przepływem powietrza powinien spełniać wymagania zawarte w normie PN-EN 12941 „Sprzęt ochrony układu oddechowego. Oczyszczający sprzęt z wymuszonym przepływem powietrza wyposażony w hełm lub kaptur. Wymagania, badanie, znakowanie.”</Text>
    <Text>Wyróżnia się trzy klasy sprzętu z wymuszonym przepływem powietrza zależne od wymaganej wartości przecieku wewnętrznego i wartości penetracji.</Text>
    <Box>
      <Text>•	Klasa THP1: 10%,</Text>
      <Text>•	Klasa THP2: 2%,</Text>
      <Text>•	Klasa THP3: 0,2%.</Text>
    </Box>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>Sprzęt może być stosowany przez osoby posiadające zarost!</Text>
    <Text>Sprzęt może być stosowany przez osoby posiadające nieregularne rysy twarzy!</Text>
    <Text>Należy zadbać o kompatybilność sprzętu z innymi środkami ochrony indywidualnej i okularami korekcyjnymi!</Text>

    <Text marginTop={2}>Podzespoły i elementy mające wpływ na bezpieczeństwo powinny być tak oznakowane, aby były identyfikowalne.</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>Znakowanie kompletnego filtrującego sprzętu ochrony układu oddechowego z wymuszonym przepływem powinno zawierać nazwę klasy ochrony.</Text>

    <Text marginTop={2}>Znakowanie kaptura i hełmu powinno zawierać, co najmniej:</Text>
    <Text>•	nazwę producenta lub znak towarowy identyfikujący producenta,</Text>
    <Text>•	adres producenta</Text>
    <Text>•	znak identyfikujący typ,</Text>
    <Text>•	numer serii lub datę produkcji,</Text>
    <Text>•	numer i rok wydania właściwej normy polskiej lub europejskiej,</Text>
    <Text>•	znak CE i czterocyfrowy numer jednostki notyfikowanej,</Text>
    <Text>•	rozmiar, jeśli jest dostępnych więcej niż jeden,</Text>

    <Text marginTop={2}>Dmuchawa i obudowa akumulatora powinny zawierać, co najmniej:</Text>
    <Text>•	nazwę producenta lub znak towarowy identyfikujący producenta,</Text>
    <Text>•	adres producenta</Text>
    <Text>•	jeśli dotyczy, wskazanie, że sprzęt jest bezpieczny w użytkowaniu w atmosferze zagrożonej wybuchem i odniesienie do odpowiedniej normy polskiej lub europejskiej,</Text>
    <Text>•	numer serii lub datę produkcji,</Text>
    <Text>•	numer i rok wydania właściwej normy polskiej lub europejskiej,</Text>
    <Text>•	zdanie „Patrz informacje producenta w oficjalnym języku kraju przeznaczenia lub odpowiedni piktogram”.</Text>

    <Text marginTop={2}>Elementy oczyszczające powinny zawierać informacje zgodne z odpowiednimi normami, dla tego typu sprzętu.</Text>
  </Box>;
}
