import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../consts";
import {States} from "../screens/Timer/StatesEnum";

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Timer: 'timer',
      Root: {
        screens: {
          WelcomeStack: {
            screens: {
              WelcomeScreen: '/',
            },
          },
          FormStack: {
            screens: {
              Form: 'form',
              Results: 'results',
              EquipmentDetail: 'equipment',
            },
          },
          HistoryStack: {
            screens: {
              History: 'history',
            },
          },
        },
      },
    },
  },
};
