import {Heading} from "native-base";
import React, {useEffect, useState} from "react";
import {EquipmentTypes, IEquipmentType} from "../../../equipment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../../consts";

export default function Header() {
  const [currentEquipment, setCurrentEquipment] = useState<IEquipmentType>();

  useEffect(() => {
    AsyncStorage.getItem(STORAGE.SELECTED_EQUIPMENT)
      .then((result) => {
        if (result != null) {
          let equipment = EquipmentTypes[result] ?? false;

          if (equipment) {
            setCurrentEquipment(equipment);
          }
        }
      });
  }, []);

  return <Heading color='white' textAlign='center' style={{
    textShadowOffset: {width: 0, height: 0},
    textShadowRadius: 6,
    textShadowColor: 'rgba(0, 0, 0, 0.3)',
  }}>
    {currentEquipment && (currentEquipment.name ?? '')}
  </Heading>
}
