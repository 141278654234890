import {ImageURISource} from "react-native";
import React from "react";
import P from "./P";
import THP from "./THP";
import {ProtectionClasses} from "../forms/EquipmentForm";

class THP2 extends THP {
  readonly name: string = 'sprzęt z wymuszonym przepływem klasy THP2';
  readonly image: ImageURISource = require('../assets/equipment/TH.jpg');
  readonly protectionClass: ProtectionClasses = ProtectionClasses.P2;
}

export default new THP2();
