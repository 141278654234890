import {Box, Button, useTheme} from "native-base";
import React, {useEffect, useState} from "react";
import ActionsheetStop from "./components/ActionsheetStop";
import {States} from "./StatesEnum";
import Clock from "./components/Clock";
import ClockButton from "./components/ClockButton";
import {useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {BottomTabParamList} from "../../Types";
import * as Notifications from "expo-notifications";
import * as Linking from "expo-linking";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import AppLoading from "expo-app-loading";
import Header from "./components/Header";
import {HistoryItem} from "../History/HistoryScreen";
import {EquipmentTypes, IEquipmentType} from "../../equipment";

export default function TimerScreen() {
  const [timer, setTimer] = useState<{state: States, expiration?: Date, timeleft_seconds: number}>();

  const [timeLeft, setTimeLeft] = useState<number>();
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const {gradient} = useTheme();
  const {navigate} = useNavigation<NavigationProp<BottomTabParamList>>();

  const [isLoading, setIsLoading] = useState(true);

  const [currentEquipment, setCurrentEquipment] = useState<IEquipmentType>();

  useEffect(() => {
    AsyncStorage.getItem(STORAGE.SELECTED_EQUIPMENT)
      .then((result) => {
        if (result != null) {
          let equipment = EquipmentTypes[result] ?? false;

          if (equipment) {
            setCurrentEquipment(equipment);
          }
        }
      });
  }, []);

  useEffect(() => {
    loadTimer();
  }, []);

  useEffect(() => {
    if (!timer) {
      return;
    }

    let interval: NodeJS.Timer;
    let expiration: Date | undefined | null;
    let timeleftSeconds = timeLeft;

    clearAllNotifications();

    if (timer.state === States.RUNNING) {
      interval = setInterval(() => {
        setTimeLeft(c => {
          if (c <= 0) {
            setExpired();
            clearInterval(interval);
          }

          return --c;
        });
      }, 1000);

      let now = (new Date()).getTime();
      if (timeLeft > 15*60) {
        notificationFifteenMinutes(
          new Date(now + (timeLeft - 60 * 15) * 1000)
        );
      }
      notificationNow(
        new Date(now + timeLeft * 1000)
      );
      expiration = new Date(now + timeLeft * 1000);

      Notifications.getAllScheduledNotificationsAsync().then(console.log);
    } else {
      expiration = null;
    }

    console.log('save timer', {
      state: timer.state,
      expiration,
      timeleft_seconds: timeleftSeconds,
    });

    // Save state
    AsyncStorage.setItem(STORAGE.TIMER, JSON.stringify({
      state: timer.state,
      expiration: expiration,
      timeleft_seconds: timeleftSeconds,
    }));

    return () => {
      clearInterval(interval);
    }
  }, [timer]);

  const loadTimer = async () => {
    console.log('loadTimer');

    AsyncStorage.getItem(STORAGE.TIMER)
      .then(async (result) => {
        console.log('getItem result', result);

        if (result) {
          let data = JSON.parse(result);
          if (data.expiration) {
            setTimeLeft(
              Math.floor( ((new Date(data.expiration)).getTime() - Date.now()) / 1000 ),
            );
          } else if (data.timeleft_seconds) {
            setTimeLeft(data.timeleft_seconds);
          }

          setTimer({
            state: data.state,
            expiration: data.expiration,
            timeleft_seconds: data.timeleft_seconds,
          });
        } else {
          let mcu = await AsyncStorage.getItem(STORAGE.MCU);
          let timeleft_seconds = mcu ? parseInt(mcu) : false;

          if (!timeleft_seconds) {
            navigate('FormStack', {
              screen: 'Form',
            });
          } else {
            setTimer({
              state: States.INITIAL,
              expiration: new Date(Date.now() + timeleft_seconds),
              timeleft_seconds: parseInt(timeleft_seconds),
            });
            setTimeLeft(timeleft_seconds);
          }
        }

        setIsLoading(false);
      });
  }

  const setExpired = () => {
    setTimer(timer => {
      return {
        ...timer,
        state: States.EXPIRED
      }
    });
    setTimeLeft(0);
  }

  const clearAllNotifications = async () => {
    await Notifications.cancelAllScheduledNotificationsAsync();
  };

  const notificationFifteenMinutes = async (date: Date) => {
    return await Notifications.scheduleNotificationAsync({
      content: {
        title: 'Zbliża się czas wymiany sprzętu',
        body: 'Za 15 minut powinieneś wymienić sprzęt!',
        data: {
          url: Linking.createURL('/timer'),
        }
      },
      trigger: {
        date: date,
      },
    });
  }

  const notificationNow = async (date: Date) => {
    return await Notifications.scheduleNotificationAsync({
      content: {
        title: 'Wymień sprzęt',
        body: 'Maksymalny czas użycia sprzętu właśnie minął!',
        data: {
          url: Linking.createURL('/timer'),
        }
      },
      trigger: {
        date: date,
      },
    });
  }

  const onPressHandler = () => {
    setTimer(s => {
      return {
        ...s,
        state: (s.state === States.RUNNING ? States.PAUSED : States.RUNNING)
      };
    });
  };

  const onStopHandler = async () => {
    await AsyncStorage.removeItem(STORAGE.TIMER);
    await AsyncStorage.removeItem(STORAGE.SELECTED_EQUIPMENT);
    await saveToHistory();

    setIsActionSheetVisible(false);
    navigate('Root', { screen: 'HistoryStack' });
  };

  const saveToHistory = async () => {
    let mcu = await AsyncStorage.getItem(STORAGE.MCU);
    let mcu_seconds = mcu ? parseInt(mcu) : null;

    let result = await AsyncStorage.getItem(STORAGE.HISTORY);
    let item: HistoryItem = {
      equipment: currentEquipment?.name ?? '',
      date: new Date(),
      time: (mcu_seconds ?? 8*60*60) - (timeLeft ?? 0),
    };

    let history: HistoryItem[];
    if (result != null) {
      history = JSON.parse(result);
    } else {
      history = [];
    }

    history.unshift(item);
    await AsyncStorage.setItem(STORAGE.HISTORY, JSON.stringify(history));
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return <Box
    flex={1}
    bg={{linearGradient: gradient}}
    paddingX={[16, 48]}
    justifyContent='center'
    safeArea>

    <Header />

    <Clock timer={timer} timeLeft={timeLeft} />
    <ClockButton timer={timer} onPress={onPressHandler} />

    <Button
      bg='white'
      _text={{color: 'secondary.500'}}
      _hover={{bg: '#e7e5e4'}}
      _pressed={{bg: '#d6d3d1'}}
      size='lg'
      onPress={() => setIsActionSheetVisible(true)}
    >
      Zakończ timer
    </Button>

    <ActionsheetStop
      isOpen={isActionSheetVisible}
      onStop={onStopHandler}
      onClose={() => setIsActionSheetVisible(false)}
    />
  </Box>;
}

