import React, {useEffect, useState} from "react";
import {Alert, Box, HStack, Image, ScrollView, Text, useBreakpointValue, View, VStack} from "native-base";
import GradientButton from "../../components/GradientButton";
import {chunk} from 'lodash';
import {EquipmentTypes, IEquipmentType} from "../../equipment";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {NavigationProp} from "@react-navigation/core/src/types";
import {FormParamList} from "../../Types";
import {ProtectionClasses} from "../../forms/EquipmentForm";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import AppLoading from "expo-app-loading";
import ContentWrapper from "../../components/ContentWrapper";

export default function ResultsScreen() {
  const { t } = useTranslation('ResultsScreen');
  const { navigate } = useNavigation<NavigationProp<FormParamList>>();

  const chunkSize = useBreakpointValue([2, 3]);

  const [isLoading, setIsLoading] = useState(true);
  const [protectionClass, setProtectionClass] = useState<ProtectionClasses>();

  const types = Object.entries(EquipmentTypes).filter(([key, type]) => {
    if (protectionClass === ProtectionClasses.P1_P2) {
      return type.protectionClass === ProtectionClasses.P1 || type.protectionClass === ProtectionClasses.P2;
    } else if (protectionClass === ProtectionClasses.P2_P3) {
      return type.protectionClass === ProtectionClasses.P2 || type.protectionClass === ProtectionClasses.P3;
    } else {
      return type.protectionClass === protectionClass;
    }
  });
  const rows = chunk(types, chunkSize);

  useEffect(() => {
    AsyncStorage.getItem(STORAGE.PROTECTION_CLASS)
      .then((result) => {
        setProtectionClass(parseInt(result ?? '0'));
        setIsLoading(false);
      });
  }, []);

  const onPressItem = (type: IEquipmentType, index: string) => {
    navigate('EquipmentDetail', {
      id: index,
    });
  };

  const renderItem = (type: IEquipmentType, key: string) => {
    return <Box borderWidth={1} borderColor='gray.100' flex={1/chunkSize} key={key}>
      <Box padding={2} flex={1} alignItems='center'>
        <Image source={type.image} alt={type.name} size='xl' />

        <Text textAlign='center' marginBottom={3} fontSize={16}>
          {type.name}
        </Text>

        <GradientButton _gradientStack={{flex: 1}} marginTop='auto' onPress={() => onPressItem(type, key)}>
          {t('check')}
        </GradientButton>
      </Box>
    </Box>
  };

  const renderAlert = (text: string, status: 'info' | 'warning' | 'success' | 'error' = 'info') =>
    <Alert w="100%" status={status} colorScheme={status}>
      <VStack space={2} flexShrink={1} w="100%">
        <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
          <HStack flexShrink={1} space={2} alignItems="center">
            <Alert.Icon />
            <Text fontSize="md" fontWeight="medium" color="coolGray.800">{text}</Text>
          </HStack>
        </HStack>
      </VStack>
    </Alert>

  let element;

  if (protectionClass === ProtectionClasses.NONE) {
    element = renderAlert(t('no_protection_class'));
  } else if (protectionClass === ProtectionClasses.INSULATING) {
    element = renderAlert(t('insulating'), 'warning');
  } else if (protectionClass === ProtectionClasses.ABSORBING) {
    element = renderAlert(t('absorbing'), 'warning');
  } else {
    element = <VStack>
      {rows.map((row, index) =>
        <HStack key={index}>
          {row.map(([key, item]) => renderItem(item, key))}
        </HStack>
      )}
    </VStack>
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ContentWrapper>
      <View paddingY={2}>
        {element}
      </View>
    </ContentWrapper>
  );
}
