import React, {useEffect, useRef, useState} from "react";
import {NativeBaseProvider} from "native-base";
import {theme} from "./Theme";
import Navigation from './navigations';
import {StatusBar} from "expo-status-bar";
import AppLoading from 'expo-app-loading';
import './languages';
import {Platform} from "react-native";
import * as Notifications from 'expo-notifications';
import {Subscription} from "@unimodules/react-native-adapter/src/EventEmitter";
import {
  useFonts,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold, Inter_700Bold
} from '@expo-google-fonts/inter';

const config = {
  dependencies: {
    'linear-gradient': require('expo-linear-gradient').LinearGradient
  },
}

Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    };
  },
});

export default function App() {
  let [fontsLoaded] = useFonts({
    'Inter-ExtraLight': Inter_200ExtraLight,
    'Inter-Light': Inter_300Light,
    'Inter-Regular': Inter_400Regular,
    'Inter-Medium': Inter_500Medium,
    'Inter-SemiBold': Inter_600SemiBold,
    'Inter-Bold': Inter_700Bold,
  });

  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();

  useEffect(() => {
    registerNotificationChannel();

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      console.log('notificationReceived');
      console.log(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log('responseReceived');
      console.log(response);
    });

    return () => {
      notificationListener.current && Notifications.removeNotificationSubscription(notificationListener.current);
      responseListener.current && Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const registerNotificationChannel = async () => {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  };

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <NativeBaseProvider theme={theme} config={config}>
        <StatusBar style="dark"/>
        <Navigation/>
      </NativeBaseProvider>
    );
  }
}
