import {IEquipmentType} from "./index";
import {ImageURISource} from "react-native";
import {Box, HStack, Text, VStack} from "native-base";
import React from "react";
import {TFunction} from "i18next";
import {ProtectionClasses} from "../forms/EquipmentForm";

export default abstract class P implements IEquipmentType {
  abstract readonly name: string;
  abstract readonly image: ImageURISource;
  abstract readonly protectionClass: ProtectionClasses;

  abstract readonly code: string;
  abstract readonly oil: string;
  abstract readonly aerosol: string;

  readonly videos = [
    require('../assets/equipment/P.mov'),
    require('../assets/equipment/SealCheck.mov'),
  ];

  description = (t: TFunction): React.ReactNode => <VStack space={2}>
    <Text>Półmaska jest częścią twarzową pokrywającą nos, usta i podbródek (rysunek 12). Posiada ona zawór lub zawory wydechowe ułatwiające oddychanie. Sprzęt ten może być przystosowany do kompletowania z 1 lub 2 elementami oczyszczającymi (np. filtrem, pochłaniaczem lub filtropochłaniaczem) montowanymi na łącznikach z zaworem wdechowym.</Text>
    <Text>Półmaski powinny spełniać wymagania normy PN-EN 140 „Sprzęt ochrony układu oddechowego. Półmaski i ćwierćmaski. Wymagania, badanie, znakowanie.”</Text>
    <Text>Półmaski powinny być skompletowane z odpowiednim elementem oczyszczającym (filtr lub komplet filtrów).</Text>

    <Text>Filtry składają się z układu włóknin filtracyjnych połączonych ze sobą na obwodzie (filtry płaskie); luźnych włókien lub układu włóknin filtracyjnych, zamkniętych w obudowie umożliwiającej swobodny przepływ powietrza (filtry kapsułowane) lub włókniny plisowanej z otworem wylotowym i łącznikiem gwintowym lub bagnetowym (filtry kasułowane z łacznikiem). Zasada ich działania polega na wychwytywaniu cząstek zanieczyszczeń ze strumienia przepływającego powietrza przez materiał filtracyjny. Filtry dopiero po skompletowaniu z odpowiednią częścią stanowią sprzęt o zróżnicowanym stopniu skuteczności.</Text>
    <Text>Filtry powinny spełniać wymagania, zawarte w normach PN-EN 143 „Sprzęt ochronny układu oddechowego. Filtry. Wymagania, badanie, znakowanie”.</Text>
    <Text>Klasyfikacja filtrów odnosi się bezpośrednio do ich skuteczności filtracji dwóch aerozoli testowych: mgły oleju parafinowego oraz aerozolu chlorku sodu.</Text>

    <VStack space={1}>
      <Text textAlign='center' fontWeight='semibold'>{this.code}</Text>
      <HStack alignItems='center'>
        <Text flex={1}>Maksymalna penetracja mgły oleju parafinowego:</Text>
        <Text fontWeight='semibold'>{this.oil}%</Text>
      </HStack>
      <HStack alignItems='center'>
        <Text flex={1}>Maksymalna penetracja aerozolu chlorku sodu:</Text>
        <Text fontWeight='semibold'>{this.aerosol}%</Text>
      </HStack>
    </VStack>
  </VStack>;

  warnings = (t: TFunction): React.ReactNode => <Box>
    <Text>•	Półmaski nie powinny być stosowane przez osoby posiadające zarost!</Text>
    <Text>•	Półmaski nie powinny być stosowane przez osoby posiadające nieregularne rysy twarzy uniemożliwiające zachowanie szczelności dopasowania!</Text>
    <Text>•	Należy zadbać o kompatybilność półmaski z innymi środkami ochrony indywidualnej i okularami korekcyjnymi!</Text>
    <Text>•	Półmaska powinna być skompletowana z odpowiednim elementem oczyszczającym!</Text>
  </Box>;

  labeling = (t: TFunction): React.ReactNode => <Box>
    <Text>Znakowanie półmaski powinno obejmować, co najmniej:</Text>
    <Text>•	nazwę producenta lub znak towarowy identyfikujący producenta,</Text>
    <Text>•	adres producenta,</Text>
    <Text>•	rozmiar (jeśli półmaska występuje więcej niż w jednym rozmiarze),</Text>
    <Text>•	numer lub rok wydania właściwej normy polskiej lub europejskiej,</Text>
    <Text>•	znak CE i czterocyfrowy numer jednostki notyfikowanej,</Text>
    <Text>•	numer serii lub datę produkcji.</Text>

    <Text marginTop={2}>Filtry, których materiał filtrujący jest umieszczony wewnątrz obudowy (filtry kapsułowane) powinny wyróżniać się białą barwą obudowy lub białą etykietą oraz posiadać, co najmniej oznaczenie:</Text>
    <Text>•	klasy i typu filtra,</Text>
    <Text>•	informację, że filtr jest przeznaczony do sprzętu dwufiltrowego (jeśli dotyczy),</Text>
    <Text>•	numer i rok wydania właściwej normy polskiej lub europejskiej,</Text>
    <Text>•	przynajmniej rok dopuszczalnego okresu przechowywania,</Text>
    <Text>•	nazwę producenta lub znak towarowy identyfikujący producenta,</Text>
    <Text>•	adres producenta,</Text>
    <Text>•	napis w oficjalnym języku kraju przeznaczenia: „Patrz informacje podane przez producenta” lub odpowiedni piktogram,</Text>
    <Text>•	znak identyfikujący typ wyrobu,</Text>
    <Text>•	znak CE i czterocyfrowy numer jednostki notyfikowanej,</Text>
    <Text>•	numer serii lub datę produkcji.</Text>

    <Text marginTop={2}>Filtry wykonane w całości z materiału filtracyjnego (bez obudowy) powinny mieć oznakowanie zawierające przynajmniej odpowiedni typ i klasę filtru oraz znak identyfikujący wyrób.</Text>
    <Text>Jeśli na filtrze nie umieszczono któregokolwiek z wyżej wymienionych oznaczeń powinno być ono uwzględnione na opakowaniu jednostkowym filtru. Bez konieczności otwierania opakowania powinny być widoczne informację o roku wygaśnięcia dopuszczalnego okresu przechowywania, znak identyfikujący wyrób i zalecane warunki przechowywania.</Text>
  </Box>;
}
