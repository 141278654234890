import React from "react";
import {Box, Heading, Image, ScrollView, VStack, Hidden} from "native-base";
import {NavigationProp, useNavigation, useRoute} from "@react-navigation/native";
import {RouteProp} from "@react-navigation/core/src/types";
import {FormParamList, RootStackParamList} from "../../Types";
import {EquipmentTypes} from "../../equipment";
import {Accordion} from "./components/Accordion";
import {useTranslation} from "react-i18next";
import GradientButton from "../../components/GradientButton";
import {Video} from "expo-av";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {STORAGE} from "../../consts";
import ContentWrapper from "../../components/ContentWrapper";

export default function EquipmentDetailScreen() {
  const {params: {id}} = useRoute<RouteProp<FormParamList, 'EquipmentDetail'>>();
  const { t } = useTranslation('EquipmentDetailScreen');
  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();

  const equipment = EquipmentTypes[id];

  const handleOnPress = async () => {
    await AsyncStorage.setItem(STORAGE.SELECTED_EQUIPMENT, id);
    await AsyncStorage.removeItem(STORAGE.TIMER);

    navigate('Timer');
  };

  return (
    <ContentWrapper>
      <Accordion>
        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('description')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            {equipment.description(t)}

            <Image source={equipment.image} alt={equipment.name} size='xl' alignSelf='center' />
          </Accordion.Details>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('howToUse')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            <Box flexDirection='row'>
              {equipment.videos.map((video, index) =>
                <Box style={{width: '50%'}} key={index}>
                  <Video
                    key={index}
                    style={{
                      marginTop: 2,
                      height: 200,
                    }}
                    source={video}
                    useNativeControls
                    resizeMode="contain"
                    isLooping
                  />
                </Box>
              )}
            </Box>
          </Accordion.Details>
        </Accordion.Item>

        <Accordion.Item>
          <Accordion.Summary>
            <Heading fontSize={20}>{t('warningsAndLabeling')}</Heading>
          </Accordion.Summary>

          <Accordion.Details>
            <VStack space={2}>
              <Heading size='sm'>{t('warnings')}:</Heading>
              {equipment.warnings(t)}

              <Heading size='sm'>{t('labeling')}:</Heading>
              {equipment.labeling(t)}
            </VStack>
          </Accordion.Details>
        </Accordion.Item>
      </Accordion>

      <Hidden platform={['web']}>
        <Box marginTop='auto'>
          <GradientButton _gradientStack={{flex:1, marginTop: 5, marginBottom: 10}} size='lg' onPress={handleOnPress}>
            {t('runTimer')}
          </GradientButton>
        </Box>
      </Hidden>
    </ContentWrapper>
  );
}
